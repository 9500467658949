
const anaLabelMap = {
  UPPER_LEFT_LABEL: 'lu_corner_mark_', // 左上
  UPPER_RIGHT_LABEL: 'ru_corner_mark_', // 右上
  LOWER_LEFT_LABEL: 'll_corner_mark_', // 左下
  LOWER_RIGHT_LABEL: 'rl_corner_mark_', // 右下
}

function getShowType(getCornerPropertyUpsell, key) {
  // 可能是色块也可能是属性 需要根据最终展示的类型来判断
  // 属性优先
  if (getCornerPropertyUpsell && getCornerPropertyUpsell?.isShowColorBlock === 'noShow') {
    return getCornerPropertyUpsell?.type === 'text' ? 'word' : 'pic'
  }

  if (getCornerPropertyUpsell && getCornerPropertyUpsell?.isShowColorBlock === 'show') {
    if ('color_information' === key) {
      return 'color'
    } else {
      return getCornerPropertyUpsell?.type === 'text' ? 'word' : 'pic'
    }
  } 

  if ('color_information' === key) {
    return 'color'
  }
}

// 如果命中属性类卖点标签，埋点需要特殊处理
function checkCornerPropertyUpsell({ key, label, curData }) {
  if ('LOWER_RIGHT_LABEL' !== label) return false
  const getCornerPropertyUpsell = curData?.cornerPropertyUpsell

  const showType = getShowType(getCornerPropertyUpsell, key)

  if (showType) {
    const anafeilds = ['show_attribute_labels', curData?.cornerPropertyUpsell?.tagId || '-', showType]
    return anafeilds.join('_') 
  }

  return false
}
  
export const getLocateLabelsAna = (locateLabels, visibleSet, bffGoodsInfo) => {
    
  const arr = Array.from(visibleSet).map(label => {
    const curData = locateLabels[label]
    const isCustom = curData.materialValueKey === 'custom'
    const key = isCustom ? `custom_${curData.subscriptType}` : curData.materialValueKey
    const id = curData.locateLabelId || '-'

    const checked = !bffGoodsInfo && checkCornerPropertyUpsell({ key, label, curData })

    if (checked) return checked
  
    return curData.appTraceInfo || `${anaLabelMap[label]}${key}_${id}`
  })
  return arr.filter(Boolean).join('|')
}
  
